<template>
  <page-header-wrapper>
    <a-card :bordered='false'>
      <!-- 条件搜索 -->
      <div class='table-page-search-wrapper'>
        <a-form layout='inline'>
          <a-row :gutter='48'>

            <a-col :md='8' :sm='24'>

              <a-form-item label='分类名称' prop='categoryName'>

                <a-input v-model='queryParam.name' placeholder='请输入分类名称'
                         allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md='!advanced && 8 || 24' :sm='24'>
              <span class='table-page-search-submitButtons'
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type='primary' @click='handleQuery'><a-icon type='search' />查询</a-button>
                <a-button style='margin-left: 8px' @click='resetQuery'><a-icon type='redo' />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class='table-operations'>
        <a-button type='primary' @click='$refs.createForm.handleAdd()'
                  v-hasPermi="['dynamic:graph/category:add']">
          <a-icon type='plus' />
          新增
        </a-button>
<!--        <a-button type='primary' @click='handleExport' v-hasPermi="['dynamic:graph/category:export']">-->
<!--          <a-icon type='download' />-->
<!--          导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync='tableSize'
          v-model='columns'
          :refresh-loading='loading'
          @refresh='getList' />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref='createForm'
        @ok='getList'
      />
      <!-- 数据展示 -->
      <a-table
        :loading='loading'
        :size='tableSize'
        rowKey='id'
        :columns='columns'
        :data-source='list'
        :pagination='false'
        bordered
        :expandedRowKeys="expandedRowKeys"
        childrenColumnName="childList"
        @expandedRowsChange="handleExpandedRowsChange"
        :scroll="{ x: 1100 }"
      >
                <span slot='createTime' slot-scope='text, record'>
          {{ parseTime(record.createTime) }}
        </span>
        <div slot="categoryPic" slot-scope="text, record">
          <img style="width: 48px;height: 48px;" preview="分类图片" :src="record.icon"/>
        </div>
        <span slot='operation' slot-scope='text, record'>
          <a-divider type='vertical' v-hasPermi="['dynamic:graph/category:edit']" />
          <a @click='$refs.createForm.handleUpdate(record, undefined)'
             v-hasPermi="['dynamic:graph/category:edit']">
            <a-icon type='edit' />修改
          </a>
<!--           <a-divider type='vertical' v-hasPermi="['dynamic:graph/category:edit']" />-->
<!--          <a @click="handleAddNext(record)"><a-icon type='add' />新增下级</a>-->
          <a-divider type='vertical' v-hasPermi="['dynamic:graph/category:remove']" />
          <a @click='handleDelete(record)' v-hasPermi="['dynamic:graph/category:remove']">
            <a-icon type='delete' />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
<!--      <a-pagination-->
<!--        class='ant-table-pagination'-->
<!--        show-size-changer-->
<!--        show-quick-jumper-->
<!--        :current='queryParam.pageNum'-->
<!--        :total='total'-->
<!--        :page-size='queryParam.pageSize'-->
<!--        :showTotal='total => `共 ${total} 条`'-->
<!--        @showSizeChange='onShowSizeChange'-->
<!--        @change='changeSize'-->
<!--      />-->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGraphCategory, listGraphCategory, delGraphCategory } from '@/api/dynamic/graphCategory'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {getTreeList} from "@/utils/util"
export default {
  name: 'GraphCategory',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      // 展开的行，受控属性
      expandedRowKeys: [],
      replaceFields: {
        id: "id",
        pid: "pid",
        children: "childList"
      },
      sortParams: {
        field: "sort",
        way: "ASC"
      },
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: "center"
        },
        {
          title: '分类名称',
          dataIndex: 'categoryName',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '分类描述',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分类图标',
          dataIndex: 'icon',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'categoryPic'}
        },
        // {
        //   title: '分类图片',
        //   dataIndex: 'categoryPic',
        //   ellipsis: true,
        //   align: 'center',
        //   scopedSlots: {customRender: 'categoryPic'}
        // },

        {
          title: '浏览次数',
          dataIndex: 'browseNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询动态图谱列表 */
    getList() {
      this.loading = true
      listGraphCategory(this.queryParam).then(response => {
        // this.list = getTreeList(response.data, "0", this.replaceFields, this.sortParams);
        this.list = response.data;
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        categoryName: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ 'id': id,'pid': row.pid }))
          return delGraphCategory(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('dynamic/dynamic-graph-category/export', {
            ...that.queryParam
          }, `动态图谱_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    //更改展开的数据
    handleExpandedRowsChange(expandedRows) {
      this.expandedRowKeys = expandedRows
    },
  }
}
</script>
